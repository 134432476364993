@import 'src/styles/imports';

.component {
  width: 400px;
  max-width: 45em;
  margin: 0 auto;

  .exp-wrapper input[type="text"] {
    height: 40px;
  }

  .screen-take-over {
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    padding: 24px 2em 10em;
    background: rgba(000, 000, 000, 0.5);

    .full-screen-search-wrapper {
      position: relative;
      z-index: 9;
      overflow: scroll;
      width: 100%;
      height: 100%;
      padding: 20px 200px 100px;
      border-radius: 30px;
      background: $color-white;

      &::-webkit-scrollbar {
        display: none;
      }

      .close {
        position: absolute;
        top: 30px;
        right: 30px;
        width: 40px;
        height: 40px;
      }
    }

    .suggested-location-dropdown {
      max-height: unset;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  &.exp-theme {
    margin: 0;
  }

  &.fake-search .search-wrapper input {
    background-color: $color-white;
  }

  .search-options {
    margin: 0 auto 1.5em;

    @include mobile {
      margin: 32px 0;
    }
  }

  .search-wrapper {
    position: relative;
    height: 40px;
    margin-bottom: 2em;
    align-items: center;

    input {
      font-size: 1em;
      width: 100%;
      height: 100%;
      padding: 1em 14em 1em 1em;
      border: 0;
      border-radius: $border-radius-large;
      background-color: $color-white;
      font-family: $font-family-sans-serif;
      outline: none;

      &::placeholder {
        font-size: .8125em;
      }
    }

    &.exp-wrapper input {
      border-radius: 0; // Use 0 instead of none
    }

    &.exp-wrapper button {
      color: $color-white;
    }

    button {
      font-size: 1em;
      top: 0;
      right: 0;
      display: flex;
      width: 6em;
      height: calc(100% - 1em);
      line-height: 1;
      padding: 0;
      border-radius: $border-radius-large;
      margin: .5em .5em .5em 0;
      align-items: center;
      justify-content: center;
      transition: all .3s ease;

      svg {
        height: 1em;
        margin-right: .2em;
        transform: rotate(90deg);

        path {
          fill: $color-black;
        }
      }
    }

    .suggested-location-dropdown {
      margin-top: 1em;
    }
  }
  
  .full-screen-search-wrapper .search-wrapper input {
    padding: 30px !important;
  }

  @media (min-width: 880px) {
    .search-wrapper {
      justify-content: center;
    }
  }

  @include mobile {
    .search-wrapper {
      font-size: 10pt;
      height: 40px;

      button {
        font-size: 9pt;
        right: 0;
        width: 4.8em;
        padding: 0 .5em;
      }

      .filter-button {
        right: 5em;

        i {
          display: none;
        }
      }

      input {
        width: 100%;
        padding-right: 9em;
      }
    }
  }
}

.search-button-container {
  position: absolute;
  right: 0;
  display: flex;
  height: 100%;
  justify-content: flex-end;
}
