// ---------------------------- Font Family -------------------------
$font-family-serif:        var(--themes-fonts-heading), 'Times New Roman', Times, serif;
$font-family-sans-serif:   var(--themes-fonts-text), 'Helvetica Neue', Helvetica, Arial, sans-serif;

// ------------------------- Font Weight Scale ----------------------
// | thin | light | regular | semi-bold | bold | extra-bold | black |
// |  100 |  300  |   400   |    600    |  700 |     800    |  900  |
$font-weight-thin:      100;
$font-weight-light:     300;
$font-weight-regular:   400;
$font-weight-semibold: 600;
$font-weight-bold:      700;
$font-weight-extra-bold: 800;
$font-weight-black:      900;
