@import 'src/styles/imports';

.component {
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;

  .hover-wrapper {
    display: flex;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    align-items: center;
    justify-content: center;
    transition: all .1s ease-in-out;
  }

  &.xs {
    width: 42px;
    height: 42px;

    svg {
      width: 18px;
      height: 18px;
    }
  }

  &.small {
    width: 48px;
    height: 48px;
  }

  &.medium {
    width: 72px;
    height: 72px;
  }

  &.large {
    width: 96px;
    height: 96px;
  }

  &.mobile {
    width: 48px;
    height: 48px;

    svg {
      height: 1em;
    }
  }
}

.component {
  &:hover {
    &.light {
      .hover-wrapper {
        background-color: $color-dark-navy-bg;

        &.zoocasa-theme {
          background-color: $color-warm-black-5;
        }
      }
    }

    &.dark {
      .hover-wrapper {
        background-color: $color-white-10;

        &.zoocasa-theme {
          background-color: $color-grey-light;
        }
      }
    }
  }

  &:focus {
    &.light {
      .hover-wrapper {
        background-color: $color-dark-navy-bg;

        &.zoocasa-theme {
          background-color: $color-warm-black-10;
        }
      }
    }

    &.dark {
      .hover-wrapper {
        background-color: $color-white-10;

        &.zoocasa-theme {
          background-color: $color-grey-light-2;
        }
      }
    }
  }
}
